/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { globalHistory } from "@reach/router";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  // ===========================================================================
  // context / ref / state

  const [setCookie] = useCookies(null);

  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [cookieMessageActive, setCookieMessageActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [pathname, setPathname] = useState(null);

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  useEffect(() => {
    if (cookiesAccepted) {
      setCookie(`accepted`, true, { path: `/` });
    }
  }, [cookiesAccepted]);

  // ===========================================================================
  // render

  return (
    <AppContext.Provider
      value={{
        cookiesAccepted,
        setCookiesAccepted,
        cookieMessageActive,
        setCookieMessageActive,
        menuActive,
        setMenuActive,
        pathname
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
