exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-give-us-a-hand-jsx": () => import("./../../../src/pages/give-us-a-hand.jsx" /* webpackChunkName: "component---src-pages-give-us-a-hand-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lets-talk-jsx": () => import("./../../../src/pages/lets-talk.jsx" /* webpackChunkName: "component---src-pages-lets-talk-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-signup-a-school-jsx": () => import("./../../../src/pages/signup-a-school.jsx" /* webpackChunkName: "component---src-pages-signup-a-school-jsx" */),
  "component---src-pages-style-guide-jsx": () => import("./../../../src/pages/style-guide.jsx" /* webpackChunkName: "component---src-pages-style-guide-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-test-forms-jsx": () => import("./../../../src/pages/test-forms.jsx" /* webpackChunkName: "component---src-pages-test-forms-jsx" */)
}

