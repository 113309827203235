/* eslint-disable import/prefer-default-export */

import "lazysizes";

import React from "react";
import AppProvider from "~context/AppContext.jsx";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
